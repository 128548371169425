import * as React from "react"
import ProductPage from "../component/productPage";
import resources from "../resources";
import Layout from "../component/Layout/Layout";

import image11 from '../assets/img/mortex1.jpg';
import image12 from '../assets/img/mortex2.jpg';

import image2 from '../assets/img/mortex3.jpg';

const MortexPage = () => {
    return (
        <Layout>
            <ProductPage
              content={resources.copy.productPage.Mortex}
              image11={image11}
              image12={image12}
              image2={image2}
            />
        </Layout>
    )
}
export default MortexPage
